<template>
  <div class="hello">

    <p> Click the counte to see me update!!</p>

    <h1 style="font-size:60px;">{{ results.counterTotal }}</h1>
    
    
  </div>
</template>

<script>
import axios from "axios";


export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function(){
      return {
          title:" This is my dashboard",
          results:{},
          timer:{}
      }
  },
  methods:{
       async getCounter() {
    try {
      
        let apiresult = await axios.get('https://prod-35.eastus.logic.azure.com:443/workflows/aa6af39094ee40d39172a8cb4fe75bc6/triggers/request/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Frequest%2Frun&sv=1.0&sig=X6pdy-okpMu3VAAjerRNQumoh0v4xEItViHbb5O2z6s');
        
        if(apiresult.status == 200){
            
            this.results = apiresult.data[0];
        }
        
    } catch (error) {
        
    console.error(error);
    }
    
}
  },
  mounted(){
      
      this.timer = setInterval(async ()=> this.getCounter(),500);
    
  },
  unmounted(){
      this.timer = null;
  }
}
</script>
